<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>员工管理</el-breadcrumb-item>
      <el-breadcrumb-item>员工列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card shadow="never">
      <div class="change">
        <el-input v-model="queryInfo.realName" placeholder="请输入姓名" clearable></el-input>
        <el-input v-model="queryInfo.phone" placeholder="请输入手机号" clearable></el-input>
        <el-select placeholder="请选择角色" v-model="queryInfo.roleFlag" clearable @focus="changeRole()">
          <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id">
          </el-option>
        </el-select>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button>导出</el-button>
        <el-button type="primary" class="add" @click="addDialogVisible = true">新增</el-button>
      </div>
      <!-- 表单数据 -->
      <el-table :data="userList" border stripe style="width: 100%"
        :header-cell-style="{ background: 'rgba(22, 119, 255, 0.0300)' }">
        <el-table-column type="index" align="center"> </el-table-column>
        <el-table-column prop="siteNum" label="站点编号" width="185" align="center">
        </el-table-column>
        <el-table-column prop="siteName" label="站点名称" width="245" align="center">
        </el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <el-avatar :size="50" src="scope.row.avatar" @error="errorHandler">
              <img src="../assets/logistics.png" />
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="姓名" width="140" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="135" align="center">
        </el-table-column>

        <el-table-column prop="roleFlag" label="角色" width="" align="center">
          <template slot-scope="scope">{{ map[scope.row.roleFlag] }}</template>
        </el-table-column>
        <el-table-column prop="flag" width="160" label="状态" align="center">
          <template slot-scope="scope">{{ map1[scope.row.flag] }}</template>
        </el-table-column>

        <el-table-column label="操作" width="275" align="center">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" icon="el-icon-edit" @click="edit(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="removeUser(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.current" :page-sizes="[10, 20, 50, 100, 200]" :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog title="新增员工" :visible.sync="addDialogVisible" width="40%" @close="closeDialog">
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="站点编号" prop="siteNum">
          <el-input v-model="addForm.siteNum" clearable></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="realName">
          <el-input v-model="addForm.realName" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="addForm.phone" clearable></el-input>
        </el-form-item>

        <el-form-item label="员工角色" prop="roleFlag">
          <el-select placeholder="请选择角色" v-model="addForm.roleFlag" @change="changeRole()">
            <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="站点名称" prop="siteName">
          <el-select v-model="addForm.siteName" placeholder="请选择站点" @change="changeSite()" class="aaa">
            <el-option v-for="item in siteList" :key="item.id" :label="item.siteName" :value="item.siteName">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addUser">保 存</el-button>
        <el-button @click="addDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 编辑用户对话框 -->
    <el-dialog title="修改员工" :visible.sync="editDialogVisible" width="40%" @close="editCloseDialog">
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="80px">
        <el-form-item label="站点编号" prop="siteNum">
          <el-input v-model="editForm.siteNum" clearable placeholder="请输入站点编号"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="realName">
          <el-input v-model="editForm.realName" clearable placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="editForm.phone" clearable placeholder="请输入手机号"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="flag">
          <el-select placeholder="请选择状态" v-model="editForm.flag" @focus="changeFlag()">
            <el-option v-for="item in map2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="员工角色" prop="roleFlag">
          <el-select placeholder="请选择角色" v-model="editForm.roleFlag" @focus="changeRole()">
            <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="站点名称" prop="siteName">
          <el-select v-model="editForm.siteName" placeholder="请选择站点" @change="changeSite()" class="aaa">
            <el-option v-for="item in siteList" :key="item.id" :label="item.siteName" :value="item.siteName">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editUser">保 存</el-button>
        <el-button @click="editDialogVisible = false">取 消</el-button>
      </span>

    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 控制添加用户对话框的显示与隐藏
      addDialogVisible: false,
      // 控制添加用户对话框的显示与隐藏
      editDialogVisible: false,
      //   员工列表
      userList: [],
      queryInfo: {
        current: 1,
        phone: '',
        realName: '',
        roleFlag: '',
        keyword: '',
        size: 10,
      },
      total: 0,

      // 添加用户表单数据
      addForm: {
        realName: '',
        phone: '',
        roleFlag: '',
        type: 'com',
        siteName: '',
        siteNum: '',
      },
      // 修改用户表单数据
      editForm: {},
      // 添加表单的验证规则对象
      addFormRules: {
        realName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        siteNum: [
          { required: true, message: '请输入站点编号', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码',
          },
        ],
        roleFlag: [
          { required: true, message: '请选择员工角色', trigger: 'change' },
        ],
        siteName: [
          { required: true, message: '请选择站点名称', trigger: 'change' },
        ],
      },
      // 修改表单的验证规则对象
      editFormRules: {
        realName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        siteNum: [
          { required: true, message: '请输入站点编号', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码',
          },
        ],
        roleFlag: [
          { required: true, message: '请选择员工角色', trigger: 'change' },
        ],
        siteName: [
          { required: true, message: '请选择站点名称', trigger: 'change' },
        ],
      },
      //  角色列表选择器
      roleList: [],
      //   站点选择器
      siteList: [],
      // 状态选择器
      flagList: [],
      //   map: ['超级管理员', '一级管理员', '运营人员', '站长', '分拣员', '配送员'],
      map: [
        '',
        '超级管理员',
        '一级管理员',
        '运营人员',
        '站长',
        '分拣员',
        '配送员',
      ],
      map1: ['禁用', '启用'],
      map2: [
        {
          value: 1,
          label: '启用',
        },
        {
          value: 0,
          label: '禁用',
        },
      ],
    }
  },
  // 钩子   创建后执行此函数
  created() {
    this.getUserList()
    this.changeSite()
    this.changeRole()
  },

  methods: {
    errorHandler() {
      return true
    },
    // 获取员工列表
    getUserList() {
      this.axios.post('/api/site/user/page', this.queryInfo).then((res) => {
        // console.log(res)
        this.userList = res.data.data.records
        this.total = res.data.data.total
      })
    },
    // 搜索框
    search() {
      this.queryInfo.current = 1
      this.getUserList
    },
    // 监听一页有几条数据
    handleSizeChange(newSize) {
      //   console.log(newSize)
      this.queryInfo.size = newSize
      this.getUserList()
    },
    // 监听第几页
    handleCurrentChange(newPage) {
      // console.log(newPage);
      this.queryInfo.current = newPage
      this.getUserList()
    },

    // 点击获取角色选择器列表
    changeRole() {
      this.axios
        .get(`/api/site/role/getRoleList?type=${this.addForm.type}`)
        .then((res) => {
          //   console.log(res)
          this.roleList = res.data.data
        })
    },
    // 点击获取状态选择器
    changeFlag() {
      this.axios
        .get(
          `/api/site/user/setStatus?id=${this.editForm.id}&flag=${this.editForm.flag}`
        )
        .then((res) => {
          //   console.log(res)
          this.flagList = res.data.data
        })
    },
    // 点击获取站点选择器列表
    changeSite() {
      this.axios.get('/api/site/info/getSiteList').then((res) => {
        // console.log(res)
        this.siteList = res.data.data
        this.siteList.forEach((item) => {
          //   console.log(item)
          if (this.addForm.siteName === item.siteName) {
            this.addForm.siteNum = item.siteNum
          }
          if (this.editForm.siteName === item.siteName) {
            this.editForm.siteNum = item.siteNum
          }
        })
      })
    },
    // 监听添加用户对话框关闭重置
    closeDialog() {
      this.$refs.addFormRef.resetFields()
    },
    // 点击添加新用户
    addUser() {
      this.$refs.addFormRef.validate((valid) => {
        // console.log(valid)
        if (!valid) return
        this.axios.post('/api/site/user/add', this.addForm).then((res) => {
          console.log(res)
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 1000,
          })
          // 重新获取用户列表
          this.getUserList()
          // 点击确认关闭
          this.addDialogVisible = false
        })
      })
    },
    // 编辑用户信息
    edit(id) {
      this.editDialogVisible = true
      console.log(id)
      this.axios.get(`/api/site/user/getUserInfo?id=${id}`).then((res) => {
        console.log(res)
        this.editForm = res.data.data
      })
    },
    // 监听修改用户对话框关闭重置
    editCloseDialog() {
      this.$refs.editFormRef.resetFields()
    },
    // 点击修改用户
    editUser() {
      this.$refs.editFormRef.validate((valid) => {
        if (!valid) return
        // console.log(valid)
        this.axios
          .post('/api/site/user/editUser', this.editForm)
          .then((res) => {
            //   console.log(res)
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1000,
            })
            // 重新获取用户列表
            this.getUserList()
            // 点击确认关闭
            this.editDialogVisible = false
          })
      })
    },
    // 根据id删除对应用户信息
    removeUser(id) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.axios.get(`/api/site/user/delUser?id=${id}`)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getUserList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
  // 搜索数据列表跟随变化
  watch: {
    queryInfo: {
      handler: function (newVal) {
        // console.log(newVal.queryInfo)
        this.getUserList()
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}

.el-table {
  margin-top: 20px;
}

.el-input,
.el-select {
  margin-right: 8px;
  width: 240px;
}

.el-card {
  margin-bottom: 20px;
}

.change {
  margin: 20px 0;

  .add {
    width: 86px;
    margin-left: 50px;
  }
}

.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>
  
